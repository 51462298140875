import { For, Show } from 'solid-js';
import { MessageBar } from '../../../../components/message-bar';
import { dayToDayJs } from '../../../../modules/date-formatting';
import type { FacilityClosure } from '../../../../graphql';

type Props = {
	timezone: string;
	closures: Array<FacilityClosure>;
};
export function FacilityClosures(props: Props) {
	return (
		<Show when={props.closures.length}>
			<MessageBar icon="info" appearance="danger" class="w-full">
				<div class="flex flex-col gap-2">
					<For each={props.closures}>
						{(closure) => (
							<p>
								{closure
									.reason!.replace('{startDate}', dayToDayJs(closure.startDay, props.timezone).format('MM/DD/YYYY'))
									.replace('{endDate}', dayToDayJs(closure.endDay, props.timezone).format('MM/DD/YYYY'))}
							</p>
						)}
					</For>
				</div>
			</MessageBar>
		</Show>
	);
}
